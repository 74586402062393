'use client';
import cx from 'classnames';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import type { ReactNode } from 'react';
import { ColorModeSwitch } from '../ColorModeSwitch/ColorModeSwitch';
import styles from './Layout.module.scss';

type Props = {
  wide: boolean;
  children: ReactNode;
};

export function Layout({ wide, children }: Props) {
  const pathname = usePathname();
  const landing = pathname && ['/', '/consulting'].includes(pathname);
  const _wide = wide || pathname?.includes('photo');
  return (
    <div className={cx([styles.Layout, landing ? styles.Landing : undefined])}>
      <header className={styles.Header}>
        <div className={styles.Wrapper}>
          <Link href="/" className={styles.Name}>
            Rolf Erik Lekang
          </Link>
          <div className={styles.SwitchWrapper}>
            <ColorModeSwitch />
          </div>
          <nav className={cx([styles.Navigation, 'no-print'])}>
            <Link href="/writing">Writing</Link>
            <Link href="/photography">Photography</Link>
            <Link href="/til">til</Link>
          </nav>
        </div>
      </header>
      <div
        className={cx([styles.Wrapper, _wide ? styles.WideWrapper : undefined])}
      >
        <main className={styles.Main}>{children}</main>
      </div>
    </div>
  );
}
